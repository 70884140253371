/* GetAllItem.module.css */
.container {
    padding: 40px;
    background-color: #f5f5f5;
    max-width: 1400px;
    margin: 0 auto;
}

.header {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
    color: #333;
    font-weight: 600;
}

.productList {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
}

.productItem {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}

.productItem:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.productImage {
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    object-fit: contain;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.productItem:hover .productImage {
    transform: scale(1.05);
}

.productDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.productName {
    font-size: 20px;
    margin: 0;
    margin-bottom: 15px;
    color: #2c2c2c;
}

.featured {
    color: red;
    font-weight: 600;
}

.noProducts {
    text-align: center;
    font-size: 18px;
    font-style: italic;
    color: #777;
}

.multipleImagesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.multipleProductImage {
    width: 100px; /* Adjust the width as needed */
    height: 100px; /* Adjust the height as needed */
    object-fit: contain;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.multipleProductImage:hover {
    transform: scale(1.1);
}