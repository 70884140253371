.checkout-container {
  max-width: 800px;
  margin: 0 auto;
  /* padding: 20px; */
}

.buttons{
  border: none;
  background: white;
}
.success-message {
  text-align: center;
  margin-top: 50px;
}

.success-message iframe {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.continue-shopping-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.checkout-form {
  margin-bottom: 20px;
}

.checkout-summary {
  margin-bottom: 20px;
}

.summary-toggle {
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.summary-toggle p {
  margin: 0;
}

.summary-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.total-section {
  margin-top: 20px;
}

.cart-total,
.cart-delivery-fee,
.cart-grand-total {
  margin-bottom: 5px;
}

.buttomCartCheckout {
  /* border-bottom: 1px solid #000000; */
  padding-bottom: 10px;
  /* padding: 10px; */
}

.checkoutImage {
  width: 65px;
  height: 65px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
}

.checkoutimageNumber {
  background-color: #968e8e;
  color: #fff;
  font-size: 11px;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: relative;
  bottom: 6.5em;
  margin: 0;
  left: 4.3em;
}

.summaryOerder {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}

.summaryOerderF{
    font-size: 14px;
}
.sumcon{
display: flex;
}

.paymentDelivery{
    padding: 20px;
}

/* Input and Select styles */
.ant-input,
.ant-select-selector {
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
}

.ant-input:focus,
.ant-select-selector:focus {
  border-color: #1890ff;
}

/* Form Item spacing */
.ant-form-item {
  margin-bottom: 16px;
}

/* Checkbox style */
.ant-checkbox-wrapper {
  font-size: 16px;
}

.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff;
}

/* Country, State, and City Select styles */
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  width: 100%;
  max-width: 300px; 

}

.ant-select-dropdown {
  max-height: 50%; /* Adjust the max-height as needed */
  overflow-y: auto;
  position: absolute;
  z-index: 1050;
  top: 100%;
  left: 0;
  /* margin-top: 4px; */
}

.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

/* Consistent Select styles */
.ant-select-single.ant-select-show-arrow .ant-select-selector {
  height: 40px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  width: 90%;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
}

.ant-select-single.ant-select-show-arrow .ant-select-selector :focus {
  border: none !important;
  outline: none !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 40px; /* Match the height of the selector */
}



.selectDropdown .ant-select-selector {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.selectDropdown .ant-select-selector:hover {
  /* border-color: #40a9ff; */
}

.selectDropdown .ant-select-selector:focus,
.selectDropdown .ant-select-focused .ant-select-selector {
  /* border-color: #40a9ff; */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.selectDropdown .ant-select-selection-item,
.selectDropdown .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 16px;
}

.selectDropdown .ant-select-arrow {
  color: #999;
}

.bottomButton{
  /* margin-bottom: 5em; */
  /* border-bottom: 2px solid #ccc; */
  padding-bottom: 1em;
  padding-top: 0.5em;
}

.bottomButton button{
  background-color: #500157;
  border: none;
  color: #fff;
  padding: 10px 15px ;
  border-radius: 10px;
}

.formWrapper{
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}
.show{
  /* display: none; */
  width: 100%;


}

.form-group{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

/* 
.form-control:focus {
  border-color: #ff7418;
  outline: none;
}

.form-control:focus,
select.form-control:focus {
  border-color: #42ff18;
  outline: none;
} */

textarea:focus, input:focus{
  outline: none;
}

.form-control {
  height: 45px;
}

input:focus {
  outline:none;
}


.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.payemntImg{
  width: 120px;
  height: 20px;
}

.payra{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PayStackMo{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.PayStackMo, .bankMo{
  background-color: #dfdfdfa5;
}

.PayStackMo img{
  width: 90px;
  height: 90px;
}

.bankMo, .PayStackMo p{
  font-size: 13px;
  margin: 0;
  padding: 10px;
  text-align: center;
}

.payra.active {
  background-color: #f0f0f0;
  border: 1px solid #000000;
  border-radius: 2px;
  padding: 15px;
}

.payra.incative{
  border: 1px solid #c4bebe;
  padding: 15px;
  border-radius: 2px;
}

.btnPaynows{
  width: 100%;
}

.btnModal{
  width: 40%;
  outline: none;
  background-color: #500157;
  color: #fff;
  border: none;
  padding: 15px;
  margin: 1em;
  border-radius: 25px;
}

.btnModaldiv{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 2em;

}

.within{
  border: 1px solid #000000;
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
}

.tran{
margin: 10px;
margin-left: 0 ;
font-size: 13px;
}

.Confirmmodal{
  /* z-index: 1000; */
  background-color: #4d4d4dd8;
  position: absolute;
  bottom: 30%;
  width: 80%;
  border-radius: 25px;
  padding: 1em;
  color: #fff;
}

.paymentOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

@media (max-width: 767px) {
  .btnModal{
    width: 100%;
    outline: none;
    background-color: #500157;
    color: #fff;
    border: none;
    padding: 15px;
    margin: 1em;
    border-radius: 25px;
  }
  
  .btnModaldiv{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 0.1em;
    flex-direction: column;
  
}
}