.abHeader {

  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #500157;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.general{
  box-sizing: border-box;
  margin: 0 auto;
  overflow-y: hidden;
}
.abSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 5em;
  gap: 4em;
}
.abSplit2{
  display: flex;
 
}

.abSplit img {
  width: 42%;
  object-fit: contain;
}

.slText {
  width: 42%;
}
.slText h2 {
  color: #500157;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slText p {
  color: #000;
  text-align: justify;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;

  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.qoute {
  background: #500157;
  padding: 2em 0;
  margin: 2em 0;
  text-align: center;
}

.qoute h2 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.qoute p {
  color: #fff;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 90%;
  margin: 0 auto;
  /* text-align: center; */
}

@media (max-width:1000px) {
  .abSplit{
    flex-wrap: wrap;
    padding: 0;
    gap: 1;
    justify-content: center;
  }
  .abSplit img{
    width: 65%;
  }
  .slText{
    width: 90%;
  }
 
  .abSplit2{
    flex-wrap: wrap-reverse;
  }
}
@media (max-width:550px) {

    .slText p{
      font-size: unset;
    }
.abSplit img{
  width: 60%;
}
.slText h2{
  font-size: 25px;
  text-align: center;
}
}