.body {
  display: flex;
  flex-direction: column;
  background: rgba(243, 243, 243, 1);
  width: 300px;
  border-radius: 25px;
  gap: 1em;
  padding-bottom: 1.6em;
}
.ripped {
  display: flex;
  width: 300px;
}
.jean{
    display: flex;
    margin: 0 1em;
    font-size: 24px;
    font-weight: bold;
    color: rgba(80, 1, 87, 1);
}
.priceDiv{
    margin: 0 1.5em;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    background: rgba(222, 233, 255, 1);
}
.price{
    font-size: 24px;
    font-weight: bold;
    color: rgba(255, 155, 5, 1);

}
.buy{
    padding: 5px 10px;
    font-size: 16px;
    color: #fff;
    background: rgba(80, 1, 87, 1);
    border-radius: 10px;
    cursor: pointer;
}