.needHelp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    background-color: #8d8d8d;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    width: 100%;
  }
  
  .needHelp img {
    width: 100px;
    height: 100px;
    margin-left: 1em;
    position: absolute;
    bottom: 0.5em;
    left: 19em;
  }
  
  .needHelp p {
    text-align: center;
    color: #fff;
    margin: 0;
  }
  