.App {
  text-align: center;
  background-color: #f4f7fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-image: url("../src/assets/image/coming.jpg");
  background-size: cover;
  background-position: center;
  background-size: cover; /* This makes it cover the entire viewport */
  background-position: center; /* This keeps the image centered */
  background-repeat: no-repeat; /* This prevents the image from repeating */
  width: 100%; /* Ensures the div takes the full width */
}

h1 {
  font-size: 3rem;
  margin: 0;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.countdown {
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0;
  color: #45e73c !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.brief {
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  bottom: 50px;
  position: absolute;
}

.brief p {
  font-size: 1rem;
  color: #2c3e50;
  line-height: 1.6;
}

.App-link {
  margin-top: 2rem;
  font-size: 1.1rem;
  color: #3498db;
  text-decoration: none;
  border: 1px solid #3498db;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.App-link:hover {
  background-color: #3498db;
  color: white;
}

.main-content {
  transition: opacity 0.3s ease;
}

.main-content.inactive {
  pointer-events: none;
  opacity: 0.5;
}

body.modal-open {
  overflow: hidden;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  /* border-color: #4096ff; */
}

.ant-select-show-search:where(
    .css-dev-only-do-not-override-2q8sxy
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  border: none;
}

.ant-select-focuced:where(.css-dev-only-do-not-override-2q8sxy).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  cursor: text;
  border: none;
}

.ant-select-focused:where(.css-dev-only-do-not-override-2q8sxy).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border: none;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-input-group
  > .ant-input:not(:first-child):not(:last-child) {
    background-color:transparent;
}
