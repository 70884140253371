.getIn {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.general{
  box-sizing: border-box;
  margin: 0 auto;
  overflow-y: hidden;
}
.getIn h2,
.getIn p {
  color: white;
  text-align: center;
  margin: 10px 0;
}

.conDetails {
  background-color: white;
  height: 400px;
}

.conOption {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4em;
}

.contactt {
  border-radius: 20px;
  border: 1px solid #3177ff;
  background: #fff;
  width: 350px;
  height: 300px;
  margin-top: -4em;
  padding: 2em 1em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contactt h2 {
  color: #500157;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 70%;
  margin: 3em auto 0;
}
.conICon {
  font-size: 30px;
  text-align: center;
}
.contactt p {
  color: #500157;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: center;
}
.conDetails h3 {
  color: #000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 70%;
  margin: 2em auto 1em;
}

@media (max-width:1244px) {
  .getIn p{
    width: 90%;
  }
}
@media (max-width:900px) {
  .contactt{
    margin: 0;
  }
  .conOption{
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .conDetails{
    height: unset;
  }
}
@media (max-width:500px) {
  .contactt{
    width: 90%;
  }
  .conDetails h3{
    font-size: 20px;
  }
}