.footer {
  background: #500157;
  padding: 3em 0;
}

.foot {
  /* display: flex; */
  align-items: flex-end;
  justify-content: space-between;
  gap: 2em;
  flex-wrap: wrap;
  
}
.twinImg {
  display: flex;
  position: relative;
}
.img1 {
  position: absolute;
  left: 0.6em;
}
.img2 {
}

.coun {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  gap: 1.4em;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.foList {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 3em;
  justify-content: flex-end;
}
.foList ul p {
  color: #fff;
  text-align: left;
  font-family: "Inter", sans-serif;

  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 1em;
}

.foList ul li {
  list-style: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2em;
}
.foList ul li:last-child {
  margin-bottom: 0;
}

.line {
  width: 1311px;
  height: 1px;
  background-color: #fff;
  margin: 3em auto 1em;
}

.pp {
  padding: 0 2em;
  text-align: center;
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media (max-width: 867px) {
  .foList ul {
    width: 50%;
  }
  .foList {
    gap: 0;
  }
  .foList ul:nth-child(3) {
    margin-top: 2em;
  }
  .foList ul:nth-child(4) {
    margin-top: 2em;
  }
  .foList ul:nth-child(2) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .foList ul:nth-child(4) {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
@media (max-width:500px) {
  .foList ul li{
    font-size: 14px;
  }
  .foList ul p{
    font-size: 18px;
  }
  .foList ul:nth-child(1){
    margin-top: 2em;

  }
  .foList ul:nth-child(2) {
    margin-top: 2em;

    display: flex;
    align-items: unset;
    flex-direction: column;
  }
  .foList ul:nth-child(4) {
    display: flex;
    align-items: unset;
    flex-direction: column;
  }
}
