.view {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
  width: 80%;
  margin: 2em auto;
}

.vIM {
  width: 40%;
  height: 600px;
}

.proDetails {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.imgOpt {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.imgOpt img {
  width: 150px;
  height: 150px;
}
.deText {
  margin-left: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deText h2 {
  color: #500157;
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 0.5em;
}
.deText span {
  color: #ff9b05;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 1em;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.deText p {
  color: #000;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deText select {
  border: 1px solid #e6e6e6;
  background: #fff;
  height: 46px;
  width: 100%;
  color: #000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  outline: none;
}

.bCart {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
}
.bCart button {
  width: 213px;
  height: 56px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn1 {
  border: 1px solid #e6e6e6;
  background: #500157;
  color: white;
}
.btn2 {
  border: 2px solid #500157;
  background: #fff;
  color: #500157;
}
