.productImage {
  width: 100%;
  height: auto;
}

.productName {
  font-size: 20px;
  font-family: "light";
  color: #000000;
}

.quantityArr {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 20px;
  align-items: center;
}

.quantityArr p {
  margin: 0;
}

.have {
  text-align: center;
  font-size: 20px;
  font-family: "light";
}

.relatedProducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.relatedProduct {
  width: 45%;
  margin-bottom: 1rem;
}

.relatedImg {
  width: 100%;
  height: 200px;
  border: 0.5px solid #ccc;
  padding: 5px;
  border-radius: 5px ;
}


/* .sizeSelector{
  display: flex;
  flex-direction: row;
} */
.unavailableSize {
  text-decoration: line-through;
  color: red;
}

.sizeOptions {
  display: flex;
  gap: 20px;
}

.sizeOption{
  border: 2px solid #ccc;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p{
  margin: 0;
}

.price{
  font-size: 15px;
  font-weight: bold;
  color: #000000;
}



.avai{
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.quantityButtons {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.quantityButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 10%;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantityButton:hover {
  background-color: #e0e0e0;
}

.quantityValue {
  margin: 0 0.5rem;
  font-size: 1rem;
}

.relatedName{
  margin: 0;
  height: 50px;
  font-family: 'light';
}

.relatedPrice{
  margin: 0;
  font-family: 'light';
}

.selectedSize {
  background-color: #007bff !important;
  color: #fff !important;
}