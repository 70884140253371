@import url("https://fonts.googleapis.com/css2?family=Montserrat:family=Roboto:family=Rubik:display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.general {
  max-width: 100%;
  overflow-x: hidden;
}
.home {
  background: #500157;
  width: 100%;
  height: 100%;
}
.homeContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 2em auto;
}

.homeContent .text {
  width: 60%;
}
.homeContent .text h1 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0.7em 0;
  width: 70%;
}

.homeContent .text h2 {
  color: #f5b40a;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 1em 0;
}

.homeContent .text h3 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.hoImg {
  width: 40%;
  height: 90%;
}
.hoImg img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.product {
  margin: 2em auto;
}
.product h2 {
  color: #000;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 0.4em;
}
.product .ab {
  color: #726e6e;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 90%;
  margin: auto;
}
.proCon .rotating-container {
  position: relative;
  display: inline-block;
  transition: transform 0.5s ease, top 0.5s ease, height 0.5s ease;
  margin: 0 5px; /* Add some spacing between images */
}

.proCon .rotating-container img {
  max-width: 100%; /* Ensure images don't exceed their container */
  height: auto;
  display: block;
}

.proImg {
  margin: 4em auto 2em;
}

.proCon {
  display: flex;
  justify-content: center;
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

.h2Name {
  font-size: 16px !important;
}
.proTog {
  /* width: 220px; */
  margin: 2em 0 2em auto;
  display: flex;
  align-items: center;
  padding-right: 2%;
  justify-content: right;
  gap: 2em;
}
.left {
  border-radius: 50%;
  border: 1px solid #ff9b05;
  background: #fff;
  width: 47px;
  height: 44px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9b05;
}

.leftB {
  border-radius: 50%;
  border: 1px solid #ff9b05;
  background: #fff;
  width: 47px;
  height: 44px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9b05;
}
.proIcon {
  font-size: 30px;
}

.right {
  border-radius: 50%;
  border: 1px solid #fff;
  background: #ff9b05;
  width: 47px;
  height: 44px;
  flex-shrink: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightB {
  border-radius: 50%;
  border: 1px solid #fff;
  background: #ff9b05;
  width: 47px;
  height: 44px;
  flex-shrink: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coll {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  /* gap: 1em; */
}
.colPro {
  width: 300px;
  border-radius: 25px;
  /* background: #f3f3f3; */
  height: 426px;
  margin-right: 10px;
}

.colPro h2 {
  color: #500157;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;

  font-weight: 500;
  line-height: normal;
}

.bra {
  margin: 2em auto;
}
.brandTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 2em;
}
.brandTop h2 {
  color: #000;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.brandTop p {
  color: #ff9b05;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background: #eef8ff;
  padding: 2em 0;
}
.brand img {
  width: 20%;
}
.comopt {
  flex-wrap: wrap;
  display: flex;
  width: 90%;
  margin: 2em auto;
  align-items: center;
  justify-content: center;
}
.comImg {
  position: relative;
  width: 60%;
  height: 400px;
}
.cm1 {
  position: absolute;
  top: 10%;
  left: 0%;
}
.cm2 {
  position: absolute;
  bottom: 20%;
  left: 10%;
}
.cm3 {
  position: absolute;
  top: 0%;
  right: 30%;
}
.cm4 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20%;
}
.cm5 {
  position: absolute;
  bottom: 0%;
  right: 40%;
}

.comText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.dot {
  width: 5px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 100%;
  flex-shrink: 0;
  background-color: #500157;
}
.dots {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.4em;
}
.dott {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  flex-shrink: 0;
  background-color: #500157;
}

.ttest h5 {
  color: #000;
  font-family: "Rubik", sans-serif;

  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0 0 0.2em 0;
}
.ttest h3 {
  color: #500157;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0 0 1em 0;
}
.ttest h4 {
  text-align: left;
  color: #726e6e;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0 0 0.6em 0;
  line-height: normal;
  width: 243px;
}
.sub {
  border-radius: 50px;
  background: #500157;
  padding: 20px 0;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sub h3 {
  color: #fff;
  font-family: "Rubik", sans-serif;

  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 1em 0 0.3em;
}
.sub p {
  color: #fff;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 593px;
  margin: 0em 0 3em;
}
.input {
  border-radius: 25px;
  background: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 3em;
  margin: 0 0 2em;
}
.inpu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
}

.input input {
  color: #a1a1a1;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
}
.input button {
  padding: 10px 20px;
  background: rgba(80, 1, 87, 1);
  width: 10em;
  color: #fff;
  font-size: 20px;
  outline: none;
  border-radius: 25px;
  border: none;
}
.inIcon {
  color: #a1a1a1;
  font-size: 30px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rotating-container {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both; /* Keep the element at 100% opacity after the animation */
  animation-timing-function: ease-in-out;
}

/* Example of staggering the animations using nth-child */
.rotating-container:nth-child(1) {
  animation-delay: 0s;
}
.rotating-container:nth-child(2) {
  animation-delay: 1s;
}
.rotating-container:nth-child(3) {
  animation-delay: 2s;
}
.rotating-container:nth-child(4) {
  animation-delay: 3s;
}
.rotating-container:nth-child(5) {
  animation-delay: 4s;
}

@media (max-width: 1200px) {
  .homeContent .text h1 {
    font-size: 50px;
  }
}
@media (max-width: 1000px) {
  .homeContent .text h1 {
    font-size: 40px !important;
  }
}
@media (max-width: 800px) {
  .homeContent .text h1 {
    font-size: 35px;
    margin: 0;
  }
  .text h2 {
    font-size: 25px !important;
    margin: 0 !important;
  }
  .text h3 {
    font-size: 18px !important;
    margin: 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
  }
}
@media (max-width: 700px) {
  .home {
    padding: 10px 0;
  }
  .homeContent .text h1 {
    font-size: 25px !important;
    margin: 0;
  }
  .text h2 {
    font-size: 15px !important;
    margin: 0 !important;
  }
  .text h3 {
    font-size: 14px !important;
    margin: 0;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
  }
  .product .ab {
    font-size: 16px;
  }
  .product h2 {
    font-size: 16px;
  }
  .input {
    width: 100% !important;
  }
  .inpu {
    width: unset;
  }
  .inpu svg {
    display: none;
  }
}
@media (max-width: 500px) {
  .input input {
    width: 100%;
  }
  .sub h3 {
    font-size: 28px;
    text-align: center;
  }
}
@media (max-width: 900px) {
  .subscribe {
    display: none;
  }
  .sub p {
    width: 95%;
  }
  .sub h3 {
    font-size: 25px;
  }
  .input button {
    width: unset;
    padding: 1em;
  }
  .sub {
    padding: 20px;
    width: 90%;
  }
  .input {
    justify-content: space-between;
    padding: 3em 3px;
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  .rotating-container img {
    width: 85%;
  }
}
.mobile-image {
  width: 90%;
  height: 400px !important; /* Allows the height to adjust proportionally based on the image's aspect ratio */
  display: block; /* Ensures that the image is centered within its container */
  margin: 0 auto; /* Centers the image horizontally */
  object-fit: contain;
}

.homeMobile {
  background-image: url("../assets/image/bg90.jpeg");
  height: 75vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.shopfor {
  font-size: 20px;
  font-family: "light";
  color: #ff9b05;
  /* border: 1px solid red ; */
  background-color: #500157;
  border: none;
  padding: 10px;
}

.shopWant {
  text-decoration: none;
  padding-top: 20em;
  padding-left: 1.5em;
}

.shopWant p {
  font-size: 25px;
  color: #fff;
  font-family: "light";
  margin: 0;
}

.proPri {
  display: flex;
  /* align-items: flex-start;
  justify-content: flex-start; */
  flex-direction: column;
}
.proPri p {
  color: #ff9b05;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
}

.proPri button {
  border-radius: 10px;
  background: #500157;
  width: 97px;
  height: 35px;
  flex-shrink: 0;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  outline: none;
  border: none;
}

@media (max-width: 768px) {
  .proTog {
    margin: 0;
  }
  .coll {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .colPro {
    width: 100%;
    height: auto;
    margin-bottom: 5em;
  }

  .colPro img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .left {
    border-radius: 50%;
    border: 1px solid #fff;
    background: #500157;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    right: 19.2rem;
    top: 10rem;
  }

  .leftB {
    border-radius: 50%;
    border: 1px solid #fff;
    background: #500157;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: relative;
    right: 19.2rem;
    top: 7rem;
  }
  .proIcon {
    font-size: 30px;
  }

  .right {
    border-radius: 50%;
    border: 1px solid #fff;
    background: #ff9b05;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    top: 10rem;
    left: 0.2rem;
  }

  .rightB {
    border-radius: 50%;
    border: 1px solid #fff;
    background: #ff9b05;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    top: 7rem;
    left: 0.2rem;
  }

  .colPro h2 {
    color: #500157;
    font-family: "Montserrat", sans-serif;
    font-size: 13px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .proPri {
    border-radius: 0;
    background: transparent;
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .proPri p {
    font-size: 13px;
    margin: 0;
    text-align: center;
    width: 90%;
  }
}

.newArr {
  font-family: "light";
  text-align: center;
  text-decoration: underline;
  font-size: 18px;
}

.natureReady {
  background-image: url("../assets/image/27.png");
  height: 50vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 10em;
}

.natureReady h4 {
  color: #ff9b05;
  font-family: "light";
  text-align: center;
  font-weight: 900;
  font-size: 24px;
}

.natureReady p {
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.natureRed {
  align-items: center;
  display: flex;
  justify-content: center;
}
