@import url("https://fonts.googleapis.com/css2?family=Montserrat:display=swap");

.nav {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 1em 5em 1em !important;
  position: sticky;
  top: 0;
  background-color: #fff; /* Add a background color to the navigation */
  z-index: 100; /* Ensure the navigation appears above other elements */
}



.nav img {
  width: 70px;
  height: 50.667px;
}

.nav .list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.nav .list li {
  list-style: none;
}
.nav .list a {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  transition: 0.3s ease;
}

.nav .list a:hover {
  color: #500157;
  transition: 0.3s ease;
}

.nav button {
  padding: 1em;
  flex-shrink: 0;
  border-radius: 6px;
  color: #500157;
  background: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
 font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
}

/* .nav-btn{
  display: none;
} */
.cartStyles {
  color: "White";
  text-decoration: "none";
}
@media (max-width: 760px) {
  .nav-btn {
    display: block;
    z-index: 5;
  }

  /* .list.din {
    position: fixed;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100vh;
    background-color: #324;
    color: #fff;
    padding: 20px;
    transition: left 0.3s ease;
    z-index: 10;
  } */

  .list.din.open {
    left: 0;
  }

  .list li {
    display: none;
  }

  .cartStyles {
    display: none;
  }

  .cartStyles.open {
    display: flex;
    z-index: 4;
    justify-content: center;
    align-items: flex-end;
    top: 255px;
    font-size: 20px;
    position: relative;
    color: #fff;
  }

  .list.din li {
    display: flex;
    color: #fff;
  }

  .list.din a {
    color: #fff;
    text-decoration: none;
  }

  /* .nav-close-btn {
    display: flex;
    position: absolute;
    top: 1.8em;
    right: 2em;
    z-index: 5;
    padding: 1em;
    flex-shrink: 0;
    border-radius: 6px;
    background: #500157;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    outline: none;
  } */

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    display: none;
  }

  .overlay.open {
    display: block;
  }

  .logo{
    display: none;
  }

  .logoSide{
    height: 50px ;
    
  }
}



.side-modal {
  position: fixed;
  top: 0;
  left: -280px;
  width: 280px;
  height: 100vh;
  background-color: white;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
}

.side-modal.open {
  left: 0;
}

.side-modal-content {
  padding: 20px;
}

.side-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.side-modal h3 {
  margin-bottom: 10px;
}

.side-modal ul {
  list-style-type: none;
  padding: 0;
}

.side-modal li {
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.cart-info {
display: flex;
justify-content: space-between;
align-items: center;
  position: relative;
  
  border: 1px solid;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
}

.cart-info p{
  margin: 0;
}

.sideText {
  border-bottom: 1px solid #ccc;
  margin: 0;
  /* padding-bottom: 10px; */
  text-decoration: none;
  color: #3d3d3d;
  font-family: 'light';
  font-size: 12px;
}

.sideTextContainer{
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.mobileCon{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mobile-cart{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
}

.mobile-cart span{
  color: #ff9b05;
  font-size: 18px;
}

.main-content {
  transition: opacity 0.3s ease;
}

.main-content.inactive {
  pointer-events: none;
  opacity: 0.5;
  overflow: hidden;
}

.dt{
  margin: 0;
  font-family: 'light';
  font-size: 26px;
  color: #ff9b05 ;
  text-decoration: none;
}

.dt span{
  color: #500157;
}

@media (min-width: 320px) {
  .dt{
    font-size: 26px;
  }
}


/* ... previous CSS code ... */
.cartModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.cartModal.open {
  opacity: 1;
  pointer-events: auto;
}

.cartModalContent {
  width: 335px;
  background-color: #fff;
  /* padding: 20px; */
  overflow-y: auto;
  height: 100%;
}

.cartModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.productName, .productPrice{
font-size: 13px;
margin: 0;
}
.productName{
  width: 200px;
}
.removeButton{
  position: relative;
  top: -5px;
  left: 15px;
  color: #000;
  border: none;
 padding: 0;
 margin: 0;
  cursor: pointer;

}

.productTop{
display: flex;
align-items: center;
justify-content: space-between;

}

.mycart {
  margin: 0;
  font-family: 'light';
  color: #ff9b05;
  font-size: 18px;
}

.mtcartlen {
  margin: 0;
  color: #989898;
  font-size: 10px;
}

.cartItemsWrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  overflow-y: auto;
}

.cartItemsContainer {
  flex: 1;
  /* overflow-y: auto; */
}

.cartItem {
  display: flex;
  border-bottom: 1px solid #500157;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.cartItem img {
  width: 70px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
}

.cartItem p {
  margin: 0;
}

.quantityButtons {
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 10px;
}

.quantity {
  font-size: 14px;
}

.quantityButton {
  /* background-color: #500157; */
  color: #000;
  border: none;
  padding: 10px 8px;
  margin: 0 5px;
  cursor: pointer;
  /* font-size: ; */
}

.cartItemBottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}


.cartTotal{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtotal, .shiptext{
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.total{
  font-size: 12px;
  font-weight: 200;
  color: #000000;
  margin: 0;
}

.shipping{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.buttomCart{
  border-bottom: 1px solid #000000;
  padding-bottom: 10px;
  padding: 10px;
}

.checkoutButton{
  justify-content: center;
  display: flex;
margin-top: 3em;
margin-bottom: 13em;
}

.checkoutButton p{
  border: 1px solid #000000;
  width: max-content;padding: 8px;
  border-radius: 10px;
  background-color: #500157;
  color: #fff;
  text-decoration: none !important;
  ;
}

.checkoutButton Link {
  text-decoration: none;
}

.emptyCart {
  text-align: center;
}

.emptyCart iframe {
  width: 380px;
  height: 450px;
  justify-content: center;
  display: flex;
  
}

.emptyCartH4{
  font-size: 12px;
  text-align: center;

}

.emptyCart button{
  background-color: #500157;
  color: #fff;
}

.selectedSize{
  font-size: 13px;
}