.general{
    background: pink;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    width: 45%;
    height: 450px;
    border-radius: 20px;
    padding: 32px 81px ;

}
.general span{
    font-weight: bold;
}
.booking{
    font-weight: bold;
    font-size: 30px;
}

.skip{
    color: #06BCE1;
    border: 1px solid #06BCE1;
    padding: 10px 40px;
    border-radius: 20px;
  }
  .save{
    color: white;
    background:  #06BCE1;
    padding: 10px 40px;
    border-radius: 20px;
  }
  .skipDiv span{
   font-weight: bold
  }
  .skipDiv{
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    font-weight: bold;
    gap: 1em;
    justify-content: flex-end;
  }
  .darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal text{
    
  }
  .modal {
    height: 170px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    gap: 1.5em;
    width: 100%;
    height: 450px;
    border-radius: 20px;
    padding: 32px 81px ;
     border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  .modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  
  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }
  
  .modalActions {
    position: absolute;
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }
  .link{
    background: rgb(0, 183, 255);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
  }
  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width:840px) {
    .link{
      /* width: 100%; */
    }
    .skip, .save{
      width: 100%;
      display: flex;
      padding: 10px 0;
      justify-content: center;
    }
  
    .skipDiv{
      flex-direction: column;
      gap: 1em;
      text-align: center;
      align-items: center;
    }
    .modal{
      padding: 30px 20px 0;
      gap: 10px;
      height: unset;
    }
  
    .text{
      font-size: 14px;
    }
    .ApprovedImg{
      width: 70px;
      height: 70px;
    }
    .booking{
      font-size: 23px;
    }
    .skipDiv{
      width: 100%;
    }
  }
  @media (max-width:500px) {
    .modal{
      width: 80vw;
    }
  }