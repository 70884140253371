/* AdminDashboard.css */
.admin-dashboard-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .admin-dashboard-title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .admin-dashboard-form {
    width: 100%;
  }
  
  .uploaded-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-top: 10px;
  }
  
  .uploaded-images-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    overflow-x: auto;
  }
  
  .submit-button-container {
    text-align: center;
    margin-top: 20px;
  }
  