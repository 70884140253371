.shop {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.filter {
  width: 20%;
}

.filter h2 {
  color: #500157;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.shopCont {
  width: 90%;
}

@media (max-width: 767px) {
  .shopCont {
    width: 100%;
  }

  .shop {
    display: flex;
    align-items: center;
   
  }
}

.shopCont .h2 {
  color: #500157;
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.shop .colPro {
  width: 289px;
  cursor: pointer;
}
.shop .colPro img {
  width: 100%;
}
.shop .coll {
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width:500px) {
  .h2{
    font-size: 32px !important;
  }
}